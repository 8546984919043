<template>
  <div class="tabs is-boxed is-right">
    <ul>
      <li :class="{ 'is-active': tab === 'summary' }">
        <a @click="$emit('change', 'summary')">
          <span>Summary</span>
        </a>
      </li>
      <li :class="{ 'is-active': tab === 'detail' }">
        <a @click="$emit('change', 'detail')">
          <span>Activity</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AccountHistoryOrgTabs',
  props: {
    tab: {
      type: String,
      required: true
    }
  }
}
</script>
